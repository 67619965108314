import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppourtime = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="I'm voting. Are you? Register now."
      description="Friends don't let friends miss out. Click here to forward this message."
      shareImage="ourtime"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`*Friends don't let friends miss out on _their_ vote!*
So click here to send them this message:
📲 https://saveourfuture.uk/ourtime 📲

*Register right now*
Here's a friendly assistant to guide you through the process
🤖 https://saveourfuture.uk/votebot 🤖

Register anywhere you call home (including your university address)
 - you can choose which to vote in, later.

🚨TOP TIP🚨
Get a postal vote so you can vote whenever suits you,
wherever makes more sense.

🗳️REGISTER! And join my Vote Squad!🗳️


For more democracy tips like this, sign up here:
https://saveourfuture.uk `}
    />
    <Interstitial />
  </Layout>
);

WhatsAppourtime.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppourtime;

export const query = graphql`
  query WhatsAppourtimeQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
